.desktop-header {
  background: var(--ion-color-primary);
  color: var(--ion-text-color);
  width: 100%;
}
.desktop-toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  height: 70px;
}

.desktop-logo {
  display: flex;
  align-items: center;
}

.mobile-logo {
  display: block;
  margin: 0 auto;
  width: 80%;
}

.w100 {
  width: 100%;
}

.game {
  width: 100%;
  height: 100%;
  background-color: #000;
  position: fixed;
  top: 70px;
  left: 0;
  z-index: -1;
  border: none;
}

@media (max-width: 860px) {
  .game {
    top: 0px;
    z-index: 2;
  }
}

/* Align Centre Vertically */
.login {
  height: 100%;
}
.login ion-row {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

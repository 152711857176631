ion-col > div:first-child {
  border: 1px dashed #9e9e9e;
  padding: 1em;
  border-radius: 0.2em;
}

.small {
  font-size: 0.7em;
}

.contrast {
  color: var(--ion-text-color-contrast) !important;
}

/* MAIN CONTIANER */
.main-container {
  padding: 0px;
  margin-bottom: 1em;
}

.box {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(6, minmax(100px, 1fr));
}

.box img {
  cursor: pointer;
  transition: transform 0.3s;
}

.box img:hover {
  transition: transform 0.3s;
  -ms-transform: scale(1.4);
  -webkit-transform: scale(1.4);
  transform: scale(1.4);
}

.box img {
  border-radius: 2px;
}

/* MEDIA QUERIES */

@media (min-width: 1200px) {
  .box {
    display: flex;
    flex-wrap: wrap;
  }
  .box img {
    width: 23.6%;
  }
}

@media (max-width: 900px) {
  .box {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(4, minmax(100px, 1fr));
  }
}

@media (max-width: 700px) {
  .box {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(3, minmax(100px, 1fr));
  }
}

@media (max-width: 500px) {
  h1 {
    text-align: left;
    font-size: 18px;
  }

  .box {
    display: flex;
    grid-gap: 20px;
    grid-template-columns: repeat(1, 1fr);
    text-align: center;
    overflow-x: auto;
  }

  .box img {
    width: 70%;
  }

  .noHorizontal {
    display: grid !important;
  }
  .noHorizontal img {
    width: 100% !important;
  }

  .box img:hover {
    transition: transform 0.3s;
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1.2);
  }
}

.categorybg ion-col div {
  background-color: #1b0f33;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 800 400'%3E%3Cdefs%3E%3CradialGradient id='a' cx='396' cy='281' r='514' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23654DDD'/%3E%3Cstop offset='1' stop-color='%231B0F33'/%3E%3C/radialGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='400' y1='148' x2='400' y2='333'%3E%3Cstop offset='0' stop-color='%23555EFF' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23555EFF' stop-opacity='0.5'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect fill='url(%23a)' width='800' height='400'/%3E%3Cg fill-opacity='0.4'%3E%3Ccircle fill='url(%23b)' cx='267.5' cy='61' r='300'/%3E%3Ccircle fill='url(%23b)' cx='532.5' cy='61' r='300'/%3E%3Ccircle fill='url(%23b)' cx='400' cy='30' r='300'/%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.categorybg p {
  text-align: center;
  margin: 50px auto;
}
